import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import videoPresentation from '../../assets/videos_marque/homme-femme-laptop-.mp4';

const VideoContainer = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -2;
`;

const ColorOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-15deg, #000000, transparent);
`;

const MaVideo = styled.video`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
`;

function VideoFond() {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.autoPlay = true;
            video.muted = true;
            video.controls = false;
            video.playsInline = true;
            video.play().catch((error) => {
                console.error('Error in autoplay: ', error);
                // Handle the error if autoplay fails
            });
        }

        return () => {
            if (video) {
                video.pause();
            }
        };
    }, []);

    return (
        <VideoContainer>
            <MaVideo src={videoPresentation} ref={videoRef} autoPlay loop muted playsInline />
            <ColorOverlay />
        </VideoContainer>
    );
}

export default VideoFond;
