import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Switch } from 'antd';
import Loader from '../../../../../components/LoadButton';
import SocialLink from '../SocialLink';

const ModalContent = styled.div`
  padding: 20px 0;
  text-align: center;

  .ant-upload {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-upload-select {
    img {
      height: 100px;
      width: 100px;
    }
  }

  textarea {
    border-radius: 10px;
    padding: 5px;
    border-width: 2px;

    &:focus {
      /* border-width: 1px; */
    }
  }

  label {
    font-size: 11px !important;
    color: #4d4d4d !important;
    margin-top: 0px;
  }
`;

const CustomInput = styled(Input)`
  padding: 10px 5px;
  border-radius: 10px;
  margin-top: 0px;
  border-width: 2px;

  &:focus {
    /* border-color: #7c7c7c;
    box-shadow: none; */
  }

  textarea {
    background: red;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey;
  border-radius: 5px;
  margin-top: 30px;

  .cancelButon {
    background: transparent;
    color: #000;

    &:hover {
      background: #ffa4a4;
      border-color: #ff6363;
    }
  }
`;

const SwitchContainer = styled.div`
  align-self: right;
  display: flex;
  justify-content: space-between;

  .ant-switch-inner {
    width: 100px;
  }
`;

const LoadContainer = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomButton = styled.button`
  padding: 10px 15px;
  border: 1px solid #000;
  border-radius: 20px;
  outline: none;
  background: #000;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
  margin: 10px;

  &:hover {
    background: transparent;
    color: #000;
  }
`;

const SwitchButton = styled.div`
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  background: #000;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

function EditShop({
  shopData,
  isOwner,
  ownerInfo,
  handleCancel,
  handleSave,
  isUpdating,
  shopName,
  setShopName,
  shopDescription,
  setShopDescription,
  shopAddress,
  setShopAddress,
  isActive,
  setIsActive,
  whatsapp,
  setWhatsapp,
  facebookLink,
  setFacebookLink,
  instagramLink,
  setInstagramLink,
  tiktokLink,
  setTiktokLink,
}) {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };

  const handleToggleForm = () => {
    setShowContactForm(!showContactForm);
  };

  return (
    <ModalContent>
      {showContactForm ? (
        <SocialLink
          ownerInfo={ownerInfo}
          whatsapp={whatsapp}
          setWhatsapp={setWhatsapp}
          facebookLink={facebookLink}
          setFacebookLink={setFacebookLink}
          instagramLink={instagramLink}
          setInstagramLink={setInstagramLink}
          tiktokLink={tiktokLink}
          setTiktokLink={setTiktokLink}
          handleSave={handleSave}
          handleCancel={handleCancel}
          isUpdating={isUpdating}
        />
      ) : (
        <Form layout="vertical">
          <Form.Item label="Nom du shop">
            <CustomInput value={shopName} onChange={(e) => setShopName(e.target.value)} />
          </Form.Item>
          <Form.Item label="Description">
            <Input.TextArea
              value={shopDescription}
              onChange={(e) => setShopDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Adresse">
            <CustomInput value={shopAddress} onChange={(e) => setShopAddress(e.target.value)} />
          </Form.Item>

          {isOwner && (
            <Form.Item label={null}>
              <SwitchContainer>
                <span>{isActive ? 'Suspendre le shop' : 'Activer le shop'}</span>
                <Switch checked={isActive} onChange={handleSwitchChange} />
              </SwitchContainer>
            </Form.Item>
          )}
        </Form>
      )}

      <SwitchButton onClick={handleToggleForm}>
        <ion-icon name="add-outline"></ion-icon>
      </SwitchButton>

      <FooterContainer>
        <CustomButton key="cancel" onClick={handleCancel} className="cancelButon">
          Annuler
        </CustomButton>
        <CustomButton key="save" type="primary" onClick={handleSave}>
          {isUpdating ? (
            <LoadContainer>
              <Loader height="20px" width="20px" $borderColor="#c4c4c4" $background="transparent" />
            </LoadContainer>
          ) : (
            'Sauvegarder'
          )}
        </CustomButton>
      </FooterContainer>
    </ModalContent>
  );
}

export default EditShop;
