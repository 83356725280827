import React, { useState, useEffect } from 'react';
import axios from 'axios';

const URLShortener = ({ longLink, onShortened }) => {
    const [error, setError] = useState(null);

    useEffect(() => {
        const shortenURL = async () => {
            try {
                // console.log('Attente de raccourissement de : ', longLink); // Log the long URL

                const response = await axios.get('https://is.gd/create.php', {
                    params: {
                        format: 'json',
                        url: longLink
                    }
                });

                // console.log('API Response:', response); // Log the response

                if (response.status === 200) {
                    onShortened(response.data.shorturl);
                } else {
                    throw new Error("Echec d'obtention du lien");
                }
            } catch (err) {
                // console.error('Error:', err); // Log any errors
                setError('Echec de raccourcissement du lien. Veuillez reessayez.');
            }
        };

        shortenURL();
    }, [longLink, onShortened]);

    if (error) {
        return <div>{error}</div>;
    }

    return null;
};

export default URLShortener;
