
import React, { createContext, useContext, useState } from 'react';
// import logo from '../../../src/assets/img_marque/logo-B&W.png';

// Créer un contexte pour les métadonnées
const MetaContext = createContext();
// console.log(logo);

// Créer un provider pour envelopper l'application
export const MetaProvider = ({ children }) => {
  const [metaData, setMetaData] = useState({
    title: 'Elanga',
    icon: "https://firebasestorage.googleapis.com/v0/b/elanga-shop.appspot.com/o/Ressources%2Ffavicon.ico?alt=media&token=48143663-78ba-4214-8e3b-2aec0b244429",
  });
  // icon: '../src/assets/img_marque/elanga_default.png',


  //   console.log(metaData);

  return (
    <MetaContext.Provider value={{ metaData, setMetaData }}>
      {children}
    </MetaContext.Provider>
  );
};

// Créer un hook pour accéder au contexte
export const useMeta = () => useContext(MetaContext);
