import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal, message } from 'antd';
import ShopDetails from './ShopDetails';
import EditShop from './EditShop';
import { getToken } from '../../../../utils/Cookies/getCookie';
import PropTypes from 'prop-types';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
`;

const MenuLine = styled.div`
  height: 2px;
  width: 25px;
  background: #d8d8d8;
  margin: 3px 0;
  border-radius: 10px;
  transition: all 0.3s ease;

  &:nth-child(1) {
    ${({ open }) =>
    open &&
    css`
        transform: rotate(90deg) translate(5px, 5px);
        width: 15px;
      `}
  }
  &:nth-child(2) {
    width: 15px;

    ${({ open }) =>
    open &&
    css`
        transform: rotate(-90deg);
        width: 20px;
      `}
  }
`;

const AntModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px !important;
  }
`;

function ShopMenu({ isOwner, shopData, ownerInfo, ref2 }) {
  const token = getToken();
  // console.log(shopData.shop.owner)

  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [shopName, setShopName] = useState(shopData.shop.shopName || '');
  const [shopDescription, setShopDescription] = useState(shopData.shop.shopDescription || '');
  const [shopAddress, setShopAddress] = useState(shopData.shop.shopAddress || '');
  const [isActive, setIsActive] = useState(shopData.shop.isActive || false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [whatsapp, setWhatsapp] = useState(shopData.shop.whatsapp || '');
  const [facebookLink, setFacebookLink] = useState(shopData.shop.facebookLink || '');
  const [instagramLink, setInstagramLink] = useState(shopData.shop.instagramLink || '');
  const [tiktokLink, setTiktokLink] = useState(shopData.shop.tiktokLink || '');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsEditing(false);
  };

  const startEditing = () => {
    setIsEditing(true);
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsOpen(false);
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append('shopName', shopName);
    formData.append('shopDescription', shopDescription);
    formData.append('shopAddress', shopAddress);
    formData.append('isActive', isActive);
    formData.append('whatsapp', whatsapp);
    formData.append('facebookLink', facebookLink);
    formData.append('instagramLink', instagramLink);
    formData.append('tiktokLink', tiktokLink);

    setIsUpdating(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shop/${shopData.shop._id}`, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        message.success('Shop modifié avec succès');
        setIsEditing(false); // Mettre à jour shopData avec les nouvelles données

        setTimeout(() => {
          window.location.reload(); // Actualiser la page
        }, 2000);

        setIsUpdating(false);
      })
      .catch((error) => {
        message.error('Échec de modification, réessayez !');
        setIsUpdating(false);
      });
  };

  return (
    <>
      <Menu onClick={toggleMenu} ref={ref2}>
        <MenuLine open={isOpen}></MenuLine>
        <MenuLine open={isOpen}></MenuLine>
      </Menu>
      <AntModal
        title={isEditing ? 'Modifier le shop' : 'A propos du shop'}
        open={isOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {isEditing ? (
          <EditShop
            shopData={shopData}
            isOwner={isOwner}
            ownerInfo={ownerInfo}
            handleCancel={handleCancel}
            handleSave={handleSave}
            isUpdating={isUpdating}
            shopName={shopName}
            setShopName={setShopName}
            shopDescription={shopDescription}
            setShopDescription={setShopDescription}
            shopAddress={shopAddress}
            setShopAddress={setShopAddress}
            isActive={isActive}
            setIsActive={setIsActive}
            whatsapp={whatsapp}
            setWhatsapp={setWhatsapp}
            facebookLink={facebookLink}
            setFacebookLink={setFacebookLink}
            instagramLink={instagramLink}
            setInstagramLink={setInstagramLink}
            tiktokLink={tiktokLink}
            setTiktokLink={setTiktokLink}
          />
        ) : (
          <ShopDetails isOwner={isOwner} shopData={shopData} startEditing={startEditing} />
        )}
      </AntModal>
    </>
  );
}

ShopMenu.propTypes = {
  ref2: PropTypes.object.isRequired,
};

export default ShopMenu;

