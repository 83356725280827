import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getCookies } from '../../utils/Cookies/getCookie';
import { Button } from 'antd';
import UserInfos from './DashComponents/UserInfos';
import AccessShop from '../../components/AccessMyShop';
import MesShops from './DashComponents/MesShops';
import Account from './DashComponents/Account';
import Footer from './DashComponents/Footer';
import Auth from '../../components/Auth';
import { useAuthModal } from '../../utils/Context/AuthModal';

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const LoadContainer = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    width: 100%;
    z-index: -1;
    background-image: radial-gradient(#cecece 1px, transparent 1px);
    background-size: 22px 22px;
    background-color: #ffffff;
`;

function Dashboard() {
    const [showAuthModal, setshowAuthModal] = useState(false);
    const allCookies = getCookies();
    const userId = allCookies.userId;
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { authModal, setAuthModal } = useAuthModal();

    const toggleAuthModal = () => {
        setAuthModal((prev) => !prev);
    };

    setTimeout(() => {
        setAuthModal((prev) => !prev);
    }, 3000);

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (userId) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/${userId}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const userData = await response.json();
                    setUser(userData);
                    // console.log(userData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchUserInfo();
    }, [userId]);

    if (!user) {
        return (
            <LoadContainer>
                {/* <Loader /> */}
                <Auth
                    showAuthModal={showAuthModal}
                    setshowAuthModal={setshowAuthModal}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                />
                <Button onClick={toggleAuthModal}>Se connecter</Button>
            </LoadContainer>
        );
    }

    return (
        <Container>
            <UserInfos user={user} setUser={setUser} />
            <AccessShop isAuthenticated={isAuthenticated} />
            <MesShops />
            <Account user={user} />
            <Footer />
        </Container>
    );
}

export default Dashboard;
