import React, { useState, useEffect, useRef } from 'react';
import { useMeta } from '../../utils/Context/MetaContext';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'antd';
import Loader from '../../components/LoadButton';
import { getCookies } from '../../utils/Cookies/getCookie';
import AddArticles from './ShopComponents/AddArticles';
import ShopArticles from './ShopComponents/ShopArticles';
import ShopHeader from './ShopComponents/ShopHeader';
import Footer from './ShopComponents/ShopFooter';
import TourWrapper from '../../components/TourWrapper';
import NotificationRequest from '../../components/PushNotif';
import './style.css'

const Container = styled.div`
    margin: 0 auto;
    padding: 20px;

    @media screen and (max-width: 768px) {
        padding: 5px;
    }
`;

const LoadContainer = styled.div`
    position: absolute;
    z-index: 2;
    background: #fff;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CustomModal = styled(Modal)`
    background: transparent !important;

    .ant-modal-content {
        border-radius: 20px !important;
    }
`;

const CustomButton = styled(Button)`
    padding: 20px 35px;
    margin-top: 10px;
    border-radius: 20px;
    color: #fff;
    background: #000;
    border: 1px solid;
    transition: 0.3s;

    &:hover {
        color: #000 !important;
        background: #fff !important;
        border-color: #000 !important;
    }
`;

function Shop() {
    const [shopData, setShopData] = useState(null);
    const [ownerInfo, setOwnerInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isOwner, setIsOwner] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showNotificationRequest, setShowNotificationRequest] = useState(false);
    const { id } = useParams();

    const { setMetaData } = useMeta();

    useEffect(() => {
        if (shopData && shopData.shop) {
            setMetaData({
                title: shopData.shop.shopName,
                icon: shopData.shop.logo,
            });
        }
    }, [shopData, setMetaData]);


    // console.log(shopData);

    const handleTokenReceived = (token) => {
        // console.log("FCM Token received:", token);
        // Envoyez le token au serveur pour l'enregistrer
        sendVisitorDataToServer(token);
    };

    //Enregistrement du token de notification push dans le serveur  
    const sendVisitorDataToServer = (token) => {
        const browserInfo = {
            userAgent: navigator.userAgent,
            browserName: navigator.appName,
            browserVersion: navigator.appVersion,
            os: navigator.platform,
        };

        console.log(browserInfo);

        const data = {
            browserInfo,
            pushToken: token,
            shopId: id,
        };

        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/visitor/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log('Visitor data sent successfully:', result);
            })
            .catch((error) => {
                console.error('Error sending visitor data:', error);
            });
    };

    useEffect(() => {
        const fetchShopData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shop/${id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setShopData(data);
                setLoading(false);

                const allCookies = getCookies();
                const userId = allCookies.userId;
                if (userId && userId === data.shop.owner) {
                    setIsOwner(true);


                } else {
                    setIsOwner(false);
                }
            } catch (error) {
                console.error('Error fetching shop data:', error);
                setLoading(false);
            }
        };

        fetchShopData();
    }, [id]);

    useEffect(() => {
        const fetchOwnerInfo = async () => {
            if (shopData && shopData.shop && shopData.shop.owner) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/${shopData.shop.owner}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const ownerData = await response.json();
                    setOwnerInfo(ownerData);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching owner data:', error);
                    setLoading(false);
                }
            }
        };

        fetchOwnerInfo();
    }, [shopData]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const steps = [
        {
            title: isOwner ? 'Ajout d\'articles' : 'Comment utiliser ce shop',
            description: isOwner ? 'Cliquez ici pour ajouter des produits à votre shop.' : 'Faites un tour simple pour vous familiariser avec l\'interface du shop.',
            target: () => ref1.current,
            mask: {
                style: {
                    boxShadow: 'inset 0 0 15px #fff',
                },
                color: 'rgba(35, 35, 35, 0.808)',
            },
        },

        {
            title: isOwner ? 'Modifier le shop' : 'A propos du shop',
            description: isOwner ? 'Modifez les informations de votre shop en cliquant sur le menu en haut à gauche. Ajoutez également les liens vers vos réseaux sociaux et votre numéro whatsapp.' : 'Cliquez sur le menu en haut à gauche pour voir les informations rélatives et l\'adresse du shop.',
            target: () => ref2.current,
            mask: {
                style: {
                    boxShadow: 'inset 0 0 15px #fff',
                },
                color: 'rgba(0, 0, 0, 0.808)',
            },
        },

        {
            title: isOwner ? 'Modifier | Supprimer un article' : 'Acheter | Commander',
            description: isOwner ? 'Supprimez ou Modifiez un article en le selectionnant, puis cliquez sur l\'un des bouttons en bas des photos.' : 'Selectionnez un article ou un produit qui vous intéresse pour acheter ou commander. Obtenez plus d\'informations sur celui-ci.',
            target: () => ref3.current,
            mask: {
                style: {
                    boxShadow: 'inset 0 0 15px #fff',
                },
                color: 'rgba(0, 0, 0, 0.808)',
            },
        },

        {
            title: isOwner ? 'Publiez votre shop' : 'Partager le shop.',
            description: isOwner ? 'Publiez le lien court, ou le lien complet de votre shop sur vos réseaux sociaux ou partagez le avec vos proches pour générer plus de traffic et de vente. 🎉🎊 ' : 'Partagez le shop avec vos proches pour leur permettre de profiter des offres et réduction toute l\'année.',
            target: () => ref4.current,
            mask: {
                style: {
                    boxShadow: 'inset 0 0 15px #fff',
                },
                color: 'rgba(0, 0, 0, 0.808)',
            },
        },
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowNotificationRequest(true);
        }, 8000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Container>
            {loading ? (
                <LoadContainer>
                    <Loader
                        height="25px"
                        width="25px"
                        $borderColor="#000"
                        $background="transparent"
                    />
                </LoadContainer>
            ) : (
                shopData && shopData.shop && (
                    <div>

                        {showNotificationRequest && (
                            <NotificationRequest onTokenReceived={handleTokenReceived} shopData={shopData} />
                        )}

                        <ShopHeader
                            shopData={shopData}
                            isOwner={isOwner}
                            ownerInfo={ownerInfo}
                            ref2={ref2}
                            ref4={ref4}
                        />
                        <p>{shopData.shop.description}</p>
                        {isOwner && (
                            <div>
                                <CustomButton ref={ref1} onClick={showModal}>Ajouter des produits</CustomButton>
                                <CustomModal
                                    title="Ajouter des articles"
                                    open={isModalVisible}
                                    onOk={handleOk}
                                    onCancel={handleCancel}
                                    footer={null}
                                >
                                    <AddArticles shopData={shopData} />
                                </CustomModal>
                            </div>
                        )}
                        <ShopArticles
                            isOwner={isOwner}
                            ownerInfo={ownerInfo}
                            shopData={shopData}
                            ref3={ref3}
                        />
                        <Footer shopData={shopData} />
                    </div>
                )
            )}
            <TourWrapper
                // condition={isOwner}
                steps={steps}
                localStorageKey="hasVisitedShop"
            />
        </Container>
    );
}

export default Shop;
