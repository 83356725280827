import React, { useState, useEffect } from 'react';
import { Tour } from 'antd';
import PropTypes from 'prop-types';

const TourWrapper = ({ steps, localStorageKey }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const currentPageKey = `${localStorageKey}_${window.location.pathname}`;

    if (!localStorage.getItem(currentPageKey)) {
      setTimeout(() => {
        setOpen(true);
      }, 5000);

      localStorage.setItem(currentPageKey, 'true');
    }
  }, [localStorageKey]);

  const customSteps = steps.map((step, index) => {
    const isLastStep = index === steps.length - 1;
    return {
      ...step,
      nextButtonProps: {
        children: isLastStep ? 'Terminer' : 'Suivant',
      },
      prevButtonProps: {
        children: 'Précédent',
      },
      finishButtonProps: isLastStep ? {
        children: 'Terminer',
      } : undefined,
    };
  });

  return (
    <Tour
      open={open}
      onClose={() => setOpen(false)}
      steps={customSteps}
      mask={{
        style: {
          boxShadow: 'inset 0 0 15px #333',
        },
        color: 'rgba(80, 255, 255, .4)',
      }}
    />
  );
};

TourWrapper.propTypes = {
  steps: PropTypes.array.isRequired,
  localStorageKey: PropTypes.string.isRequired,
};

export default TourWrapper;
