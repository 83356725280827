import styled from "styled-components";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Drawer, Modal } from 'antd';
import SearchBar from "../SearchArticle";
import ArticleList from "./ArticleList";
import DrawerContent from "./DrawerContent";
import Cart from "../Cart/Cart";
import './style.css';
import Skeleton from "../SkeletonArticles";
import NoArticle from "../NoArticle";

import PropTypes from 'prop-types';


const Container = styled.div`
  padding: 10px;
  width: 100%;
  position: relative;

  h3 {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;

    h3 {
      transform: translateY(-10px);
    }
  }

  .divHold {
    /* background: red; */
  }
`;


const CustomDrawer = styled(Drawer)`
  border-radius: 20px 20px 0 0 !important;
  background: green !important;

  .ant-drawer-body {
    padding: 0;
    background: #fff;
  }

  .ant-drawer-content {
    /* background: red !important; */
  }

  .ant-drawer-header {
    background: #fff;
    border: none;
  }
`;

const FloatingCartIcon = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ion-icon {
    font-size: 24px;
  }

  span {
    position: absolute;
    top: -5px;
    right: -5px;
    background: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LoadMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 5px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  transition: 0.3s;
  opacity: 0.7;
  cursor: pointer;

  &:hover{
    opacity: 1;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

function ShopArticles({ isOwner, ownerInfo, shopData, ref3 }) {
  const [shopArticles, setShopArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [currentImg, setCurrentImg] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [cartModalVisible, setCartModalVisible] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');
  const [articlesToShow, setArticlesToShow] = useState(8); // Initialiser avec 4 articles

  const handlePreview = (imgSrc) => {
    setCurrentImg(imgSrc);
    setVisible(true);
  };

  const fetchArticles = async (searchTerm = '') => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/articles/${id}?search=${searchTerm}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      if (sortOrder === 'asc') {
        data.sort((a, b) => new Date(a.creationDate) - new Date(b.creationDate));
      } else if (sortOrder === 'desc') {
        data.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
      } else if (sortOrder === 'random') {
        data.sort(() => 0.5 - Math.random());
      }

      setShopArticles(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, [id, sortOrder]);

  const showDrawer = (article) => {
    setSelectedArticle(article);
    setDrawerVisible(true);
  };


  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedArticle(null);
  };

  const handleSearch = (searchTerm) => {
    setLoading(true);
    fetchArticles(searchTerm);
  };

  const addToCart = (article) => {
    setCartItems([...cartItems, { img: article.image, title: article.title, price: article.price, currency: article.currency }]);
  };

  const openCartModal = () => {
    setCartModalVisible(true);
  };

  const closeCartModal = () => {
    setCartModalVisible(false);
  };

  const handleRemoveFromCart = (item) => {
    setCartItems(cartItems.filter(cartItem => cartItem !== item));
  };

  const handleLoadMore = () => {
    setArticlesToShow(prevCount => prevCount + 4);
  };

  return (
    <Container>
      {shopArticles.length > 0 && (
        <>
          <SearchBar onSearch={handleSearch} />
          {/* <select value={sortOrder} onChange={handleSortChange}>
            <option value="asc">Plus ancien au plus récent</option>
            <option value="desc">Plus récent au plus ancien</option>
            <option value="random">Aléatoire</option>
          </select> */}
        </>
      )}
      {loading ? (
        <Skeleton />
      ) : shopArticles.length > 0 ? (
        <div className="divHold">
          <h3>Tous les articles</h3>
          <ArticleList articles={shopArticles.slice(0, articlesToShow)} showDrawer={showDrawer} isOwner={isOwner} shopData={shopData} ref3={ref3} />
          {articlesToShow < shopArticles.length ? (
            <LoadMoreButton onClick={handleLoadMore}>Voir plus</LoadMoreButton>
          ) : (
            <LoadMoreButton disabled>Vous avez atteint la fin</LoadMoreButton>
          )}
        </div>
      ) : shopArticles.length === 0 ? (
        // <NoArticlesMessage>Aucun article trouvé</NoArticlesMessage>
        <NoArticle />
      ) : (
        // <NoArticlesMessage>Aucun article trouvé</NoArticlesMessage>
        <NoArticle />
      )}

      <CustomDrawer
        placement="bottom"
        closable={true}
        onClose={closeDrawer}
        open={drawerVisible}
        height="90vh"
        styles={{ background: '#fff' }}
      >
        {selectedArticle && (
          <DrawerContent
            article={selectedArticle}
            handlePreview={handlePreview}
            currentImg={currentImg}
            visible={visible}
            setVisible={setVisible}
            addToCart={addToCart}
            isOwner={isOwner}
            ownerInfo={ownerInfo}
            shopData={shopData}
          />
        )}
      </CustomDrawer>

      {!isOwner && (
        <FloatingCartIcon onClick={openCartModal}>
          <ion-icon name="cart-outline"></ion-icon>
          {cartItems.length > 0 && <span>{cartItems.length}</span>}
        </FloatingCartIcon>
      )}

      <Modal
        title="Votre Panier"
        open={cartModalVisible}
        onCancel={closeCartModal}
        footer={null}
      >
        <Cart cartItems={cartItems} ownerInfo={ownerInfo} shopData={shopData} onRemove={handleRemoveFromCart} />
      </Modal>
    </Container>
  );
}

ShopArticles.propTypes = {
  ref3: PropTypes.object.isRequired,
};

export default ShopArticles;
