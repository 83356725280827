import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { MetaProvider } from './utils/Context/MetaContext';
import MetaUpdater from './utils/Context/MetaUpdater';

// ====== Import Pages ==========//
import Home from './pages/Home';
import Marketplace from './pages/Marketplace';
import Shop from './pages/Shop';
import Dashboard from './pages/Dashboard';
import NotificationRequest from './components/PushNotif';

// =====Import Global Components //
import NotFound from './pages/NotFound';

// ======= IMPORT CONTEXT ========== //
import { AuthModalProvider } from './utils/Context/AuthModal';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Google Sans', sans-serif; */
    font-family: 'Poppins';
  }

  body {
    background: #f1f1f1;
  }

  body {
    /* background-color: ${(props) => (props.theme === 'light' ? '#f1f1f1' : '#2b2b2b')}; */
    /* color: ${(props) => (props.theme === 'light' ? '#2e2e2e' : '#c5c5c5')}; */
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
`;

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then((registration) => {
                // console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
                console.log('Service Worker registration failed:', error);
            });
    });
}

// ================== Gérer l'installation de la PWA avec beforeinstallprompt =====
// let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (event) => {
//     // Empêche l'affichage automatique du prompt
//     event.preventDefault();
//     deferredPrompt = event;

//     // Affiche un bouton pour permettre à l'utilisateur d'installer l'application
//     const installButton = document.getElementById('installButton');
//     // installButton.style.display = 'block';

//     installButton.addEventListener('click', () => {
//         // Affiche le prompt d'installation
//         deferredPrompt.prompt();

//         // Vérifie le choix de l'utilisateur
//         deferredPrompt.userChoice.then((choiceResult) => {
//             if (choiceResult.outcome === 'accepted') {
//                 console.log('User accepted the A2HS prompt');
//             } else {
//                 console.log('User dismissed the A2HS prompt');
//             }
//             deferredPrompt = null;
//         });
//     });
// });

// BACKEND RENDER.COM ====  https://ouigo-backend-application.onrender.com
// BACKEND LIHGTSAIL === http://54.229.4.47:3500

const App = () => {
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowNotification(true);
        }, 60000); // 60 secondes

        // Nettoyage du timer pour éviter les fuites de mémoire si le composant est démonté
        return () => clearTimeout(timer);
    }, []);

    return (
        <MetaProvider>
            <Router>
                <MetaUpdater />
                <AuthModalProvider>
                    <GlobalStyle />
                    {showNotification && <NotificationRequest />}
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<NotFound />} />
                        <Route path="/Dashboard" element={<Dashboard />} />
                        <Route path="/Marketplace" element={<Marketplace />} />
                        <Route path="/Shop/:id" element={<Shop />} />
                    </Routes>
                </AuthModalProvider>
            </Router>
        </MetaProvider>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

reportWebVitals();
