import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { Modal } from 'antd';
import styled from 'styled-components';
import { loadGoogleMaps } from '../../../../utils/googleMaps';

// Container stylisé pour la carte dans le modal
const StyledMapContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  border: 5px solid #dbdbdb;

  .gm-style button {
    background-color: #333 !important;
    color: #fff !important;
    border-radius: 8px !important;
    border: 2px solid #fff !important;
    padding: 5px 10px !important;
  }

  .gm-style button:hover {
    background-color: #555 !important;
  }
`;

const CustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
  }
`;

const MapButton = styled.div`
  height: 50px;
  width: 50px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ffffff29;
  backdrop-filter: blur(5px);
  border-radius: 15px;
  border: 1px solid #fff;

  ion-icon {
    font-size: 1.5rem;
    color: #fff;
  }
`;

const ShopMap = ({ shopData }) => {
  const { selectedAddressCoordinates, shopName, businessCategory, shopAddress } = shopData.shop;
  const coordinates = selectedAddressCoordinates.coordinates;
  const position = coordinates.length > 0 ? { lat: coordinates[1], lng: coordinates[0] } : { lat: -11.660197, lng: 27.479383 };

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    // Charger l'API Google Maps
    loadGoogleMaps()
      .then(() => {
        setGoogleLoaded(true); // Google Maps API est prêt
      })
      .catch(() => {
        setLoadingError(true); // Gestion d'erreur si l'API ne charge pas
      });
  }, []);

  if (loadingError) {
    return <div>Erreur de chargement de la carte. Veuillez réessayer plus tard.</div>;
  }

  return (
    <>
      <MapButton onClick={showModal}>
        <ion-icon name="map-outline"></ion-icon>
      </MapButton>

      <CustomModal
        title="Carte de l'emplacement"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="100%"
        style={{ top: 20 }}
        styles={{ body: { padding: 0, height: '50vh', borderRadius: '20px', margin: '0px' } }}
      >
        <StyledMapContainer>
          {googleLoaded ? (
            <GoogleMap
              mapContainerStyle={mapStyles}
              center={position}
              zoom={16}
            >
              <Marker
                position={position}
                onClick={() => setSelectedMarker(position)}
              />

              {selectedMarker && (
                <InfoWindow
                  position={selectedMarker}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <div>
                    <h4>{shopName}</h4>
                    <p>{businessCategory.join(', ')}</p>
                    <p>{shopAddress}</p>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          ) : (
            <div>Chargement de la carte...</div> // Indicateur de chargement pendant l'attente
          )}
        </StyledMapContainer>
      </CustomModal>
    </>
  );
};

export default ShopMap;
