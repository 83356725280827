import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import Loader from '../../LoadButton';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const Formulaire = styled.form`
    padding: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;

    input {
        outline: none;
        border: 1px solid #fff;
        border-radius: 20px;
        background: transparent;
        width: 100%;
        margin: 10px 0;
        font-size: 15px;
        resize: none;
        transition: 0.3s;
        font-weight: 600;
        padding: 15px;
        padding-right: 40px; /* Ajout d'espace pour l'icône */
        border-radius: 25px;
        position: relative;

        &:focus {
            box-shadow: 0 0 15px 0px #ffffff;
            background: #f4f4f46b;
        }

        &::placeholder {
            font-weight: 400;
            color: #fff;
        }
    }

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

const InputWrapper = styled.div`
    position: relative;
    width: 100%;

    .icon {
        font-size: 25px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const LoadContainer = styled.div`
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #9eff9e;
`;

const ButtonDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    left: 0;
    margin-top: 10px;

    button {
        padding: 15px 50px;
        border-radius: 25px;
        margin-top: 5px;
        gap: 40px;
        font-size: 15px;
        outline: none;
        border: 1px solid #fff;
        width: 100%;
        background: #fff;
        font-weight: 600;
        color: #000;
        cursor: pointer;
    }
`;

function Step1({ formData, handleInputChange, handleNextStep }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [isChecking, setIsChecking] = useState(false);

    useEffect(() => {
        const checkShopNameExists = async () => {
            if (formData.shopName.trim() !== '') {
                setIsChecking(true);
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shop?shopName=${formData.shopName}`, {
                        method: 'GET',
                    });
                    const data = await response.json();
                    const shopExists = data.shopList.some(shop => shop.shopName.toLowerCase() === formData.shopName.toLowerCase());
                    if (shopExists) {
                        setErrorMessage('Ce nom est déjà utilisé.');
                        message.error("Un autre shop contient déjà le nom que vous avez saisi.");
                    } else {
                        setErrorMessage('');
                    }
                } catch (error) {
                    console.error('Erreur lors de la vérification du nom du shop:', error);
                } finally {
                    setIsChecking(false);
                }
            } else {
                setErrorMessage('');
            }
        };

        const delayDebounceFn = setTimeout(() => {
            checkShopNameExists();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [formData.shopName]);

    const localHandleInputChange = (e) => {
        handleInputChange(e);
        setErrorMessage('');
    };

    const localHandleNextStep = () => {
        if (errorMessage === 'Ce nom est déjà utilisé.') {
            return;
        }
        handleNextStep();
    };

    const renderIcon = () => {
        if (formData.shopName.trim() === '') {
            return null;
        }

        if (isChecking) {
            return (
                <LoadContainer>
                    <Loader $borderColor="#ffffff" className="icon" />
                </LoadContainer>
            );
        } else if (errorMessage === 'Ce nom est déjà utilisé.') {
            return <CloseCircleOutlined className="icon" style={{ color: '#ff9e9e' }} />;
        } else if (errorMessage === '') {
            return <CheckCircleOutlined className="icon" style={{ color: '#9eff9e' }} />;
        }
        return null;
    };

    return (
        <Formulaire>
            {errorMessage && <div style={{ color: errorMessage === 'Ce nom est déjà utilisé.' ? '#ff9e9e' : '#9eff9e', marginBottom: '0' }}>{errorMessage}</div>}
            <InputWrapper>
                <input
                    type="text"
                    name="shopName"
                    value={formData.shopName}
                    onChange={localHandleInputChange}
                    placeholder="Le nom de votre Shop"
                />
                {renderIcon()}
            </InputWrapper>

            <ButtonDiv>
                <button type="button" onClick={localHandleNextStep} disabled={isChecking}>
                    Suivant
                </button>
            </ButtonDiv>
        </Formulaire>
    );
}

export default Step1;
