import React from 'react';
import Cookies from 'js-cookie';
import { notification } from 'antd';
import styled from 'styled-components';
import { getCookies } from '../../utils/Cookies/getCookie';

const Button = styled.button`
    padding: 10px 20px;
    border: 1px solid #ff7d7d;
    background: #ffe7e7;
    color: #c30000;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s;

    display: flex;
    justify-content: center;
    align-items: center;

    ion-icon{
        font-size: 20px;
    }

    &:hover{
        background: transparent;
    }

    @media screen and (max-width: 768px) {
        padding: 5px 10px;
    }
`

const Logout = ({ setUser }) => {

    const allCookies = getCookies();
    const userId = allCookies.userId;

    const handleLogout = () => {
        // Efface le cookie d'authentification
        Cookies.remove('AuthCookies');
        Cookies.remove('userId');

        setUser(false)

        // Afficher une notification pour confirmer la déconnexion
        notification.success({
            message: 'Déconnexion réussie',
            description: 'Vous avez été déconnecté avec succès.',
        });
    };

    return (
        <Button type="primary" onClick={handleLogout}>
            <ion-icon name="log-out-outline"></ion-icon> Déconnexion
        </Button>
    );
};

export default Logout;
