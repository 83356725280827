import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import p5 from 'p5';
// import GlobePhoto from '../../assets/ressources/World_map.png';

const Container = styled.div`
    padding: 10px;
    height: 100%;
    width: 70%;
    margin-top: -40px;

    @media screen and (max-width: 768px) {
        width: 85%;
    }
`;

function Globe() {
    const containerRef = useRef(null);

    useEffect(() => {
        let canvas;
        const sketch = (p) => {
            let img;
            let deg = 0;

            p.preload = () => {
                img = p.loadImage(
                    'https://upload.wikimedia.org/wikipedia/commons/e/ec/World_map_%28blue_dots%29.svg',
                );
            };

            p.setup = () => {
                canvas = p.createCanvas(
                    containerRef.current.offsetWidth,
                    containerRef.current.offsetHeight,
                    p.WEBGL,
                );
                p.background(0);
            };

            p.draw = () => {
                p.pointLight(255, 255, 255, 100, -40, 200);
                p.clear();
                p.ambientLight(120);
                p.noStroke();
                p.texture(img);
                p.rotateZ(5.87);
                p.rotateY(deg);
                p.rotateX(0);
                p.noStroke();
                p.sphere(150);
                p.ambientMaterial(255);
                deg += 0.005;
            };
        };

        const p5canvas = new p5(sketch, containerRef.current);

        return () => {
            p5canvas.remove();
        };
    }, []);

    return <Container ref={containerRef}></Container>;
}

export default Globe;
