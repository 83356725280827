import { useState } from 'react';
import styled from 'styled-components';
// import { getCookies } from '../../utils/Cookies/getCookie';
import { Avatar, Tag } from 'antd';
import EditUserModal from '../EditUserModal';
import Loader from '../../../../components/LoadButton';
import Logout from '../../../../components/Logout';

const Load = styled.div`
  padding: 20px;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* gap: 20px; */

  /* background: linear-gradient(45deg, #00b3ff, #b1f7ff); */
  width: 100%;
  border-radius: 20px;
  position: relative;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-bottom: 80px;

  @media screen and (max-width:768px) {
    padding-bottom: 60px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Button = styled.button`
position: absolute;
right: 10px;
bottom: 10px;
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid #000;
  border-radius: 20px;
  background: #000;
  color: #fff;
  cursor: pointer;  
  transition: 0.3s;

  &:hover{
    background: transparent;
    color: #000;
  }

  @media screen and (max-width:768px) {
    padding: 5px 20px;
  }
`;

const LoadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoutContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
`

function UserInfos({ user, setUser }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSave = (updatedUser) => {
    // Logique pour sauvegarder les modifications
    // console.log('Updated User:', updatedUser);
    // Mettre à jour l'utilisateur localement
    setUser({ ...user, ...updatedUser });
  };

  if (!user) {
    return <LoadContainer> <Loader /> </LoadContainer>;
  }

  return (

    <Load>
      <UserInfo>
        <Avatar size={64} src={user.picture === 'placeholder.png' ? 'default-avatar.png' : user.picture} />
        <UserDetails>
          <h3> Bienvenue à bord {user.firstName} </h3>
          {/* <p>WhatsApp: {user.whatsapp}</p> */}
          {/* <p>Registered: {new Date(user.registerDate).toLocaleDateString()}</p> */}
          <div>
            {user.canPost && <Tag color="green">Can Post</Tag>}
            {user.certifyed && <Tag color="blue">Certified</Tag>}
            {user.isConnected && <Tag color="cyan">Connected</Tag>}
            {user.isSubscribed && <Tag color="gold">Subscribed</Tag>}
            {user.userEnabled ? <Tag color="lime">Enabled</Tag> : <Tag color="red">Disabled</Tag>}
          </div>
        </UserDetails>
        <EditUserModal
          user={user}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSave={handleSave}
        />
      </UserInfo>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Modifier le compte
      </Button>
      <LogoutContainer>
        <Logout setUser={setUser} />
      </LogoutContainer>
    </Load>

  );
}

export default UserInfos;