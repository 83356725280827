export async function saveVisitorInfo(browserInfo, pushToken, userId) {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/visitor/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                browserInfo,
                pushToken,
                userId, // userId est facultatif
            }),
        });

        const resData = await response.json();
        // console.log('Response data:', resData);

        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des données');
        } else if (resData.message === 'Token updated') {
            // console.log('Token mis à jour dans la base de données');
        } else if (resData.message === 'New visitor saved') {
            // console.log('Nouveau visiteur enregistré avec succès');
        } else if (resData.message === 'Duplicate entries removed') {
            // console.log('Doublons supprimés, données mises à jour');
        }
    } catch (error) {
        console.error('Erreur:', error);
    }
}
