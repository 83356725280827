import { useState, useEffect } from "react";
import styled from "styled-components";
import { getToken } from "../../../../utils/Cookies/getCookie";
import Loader from "../../../../components/LoadButton";
import { Modal, message } from "antd"; // Importation des composants nécessaires d'Ant Design

const Container = styled.div`
    margin-top: 20px;
    padding: 20px;
    background: #f9f9f9;
    background: linear-gradient(45deg, #ffffff, #b1f7ff);
    border-radius: 20px;
    width: 100%;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
    grid-column: 1 / -1;
    color: #333;
`;

const CardContainer = styled.div`
    max-height: ${(props) => (props.$expanded ? '1000px' : '0')};
    overflow: hidden;
    transition: all 0.3s;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding-top: ${(props) => (props.$expanded ? '20px' : '0')};
    padding-bottom: ${(props) => (props.$expanded ? '10px' : '0')};

    @media screen and (max-width:768px) {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
`;

const ShopCard = styled.div`
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;

    &:hover {
        transform: translateY(-5px);
    }
`;

const ShopImage = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;

    @media screen and (max-width:768px) {
        height: 100px;
        border-radius: 10px;
    }
`;

const ShopContent = styled.div`
    padding: 15px;

    @media screen and (max-width:768px) {
        padding: 5px;
    }
`;

const ShopTitle = styled.h3`
    margin: 0;
    font-size: 1.2em;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const Button = styled.button`
    background-color: #393939;
    transition: background-color 0.3s;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 25px;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:hover {
        background-color: #212121;
    }

    &:nth-child(2) {
        background-color: #ff4d4f;
        opacity: 0.4;

        &:hover {
            background-color: #ff7875;
            opacity: 1;
        }
    }

    @media screen and (max-width:768px) {
    font-size: 20px;
    padding: 0;
    width: 30px;
    height: 30px;
    }
`;

const NoShopsContainer = styled.div`
    grid-column: 1 / -1;
    text-align: center;
    margin-top: 50px;
`;

const NoShopsText = styled.p`
    font-size: 1.2em;
    color: #666;
`;

const CreateShopButton = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #000;
    background: #000;
    color: #fff;
`;

const LoadContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
`;

const ToggleButton = styled.button`
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    transition: all 0.3s;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        color: #696969;
    }

    ion-icon{
        transform: ${(props) => (props.$expanded ? 'rotate(90deg)' : 'rotate(0)')};
        transition: 0.3s;
    }
`;

function MesShops() {
    const [loading, setLoading] = useState(false);
    const [shops, setShops] = useState([]);
    const [expanded, setExpanded] = useState(false); // Etat pour contrôler l'affichage du CardContainer
    const [shopToDelete, setShopToDelete] = useState(null); // Etat pour stocker le shop à supprimer
    const token = getToken();

    useEffect(() => {
        const fetchDataShop = async () => {
            setLoading(true);
            try {
                if (token) {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shop/user`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setShops(data.shops);
                    } else {
                        console.error('Erreur lors de la récupération des shops:', response.statusText);
                    }
                }
            } catch (error) {
                console.error('Erreur:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataShop();
    }, [token]);

    const handleDeleteShop = async (shopId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shop/${shopId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setShops(shops.filter(shop => shop._id !== shopId));
                message.success('Le shop a été supprimé avec succès');
            } else {
                console.error('Erreur lors de la suppression du shop:', response.statusText);
                message.error('Erreur lors de la suppression du shop');
            }
        } catch (error) {
            console.error('Erreur:', error);
            message.error('Erreur lors de la suppression du shop');
        }
    };

    const confirmDeleteShop = (shopId, shopName) => {
        setShopToDelete(shopId);
        Modal.confirm({
            title: 'Confirmer la suppression',
            content: (
                <>
                    Êtes-vous sûr de vouloir supprimer le shop <b> {shopName} </b> ? <br /><br />
                    <em> Cette action est irréversible, vous perdrez tous les articles présents dans ce shop. </em>
                </>
            ),
            okText: 'Oui, supprimer',
            okType: 'danger',
            cancelText: 'Non',
            onOk() {
                handleDeleteShop(shopId);
            },
            onCancel() {
                setShopToDelete(null);
            },
        });
    };

    return (
        <Container>
            <Title onClick={() => setExpanded(!expanded)}> Mes shops </Title>
            <ToggleButton onClick={() => setExpanded(!expanded)} $expanded={expanded}>
                <ion-icon name="chevron-forward-outline" $expanded={expanded}></ion-icon>
            </ToggleButton>
            {loading ? (
                <LoadContainer> <Loader /> </LoadContainer>
            ) : (
                <CardContainer $expanded={expanded}>
                    {shops.length > 0 ? (
                        shops.map((shop) => (
                            <ShopCard key={shop._id}>
                                <ShopImage src={shop.logo || 'https://via.placeholder.com/300'} alt={shop.shopName} />
                                <ShopContent>
                                    <ShopTitle>{shop.shopName}</ShopTitle>
                                    {/* <ShopDescription>{shop.shopDescription}</ShopDescription> */}
                                    <ButtonContainer>
                                        <Button onClick={() => window.location.href = `/shop/${shop._id}`} target="_blank">
                                            <ion-icon name="bag-handle-outline"></ion-icon>
                                        </Button>
                                        <Button onClick={() => confirmDeleteShop(shop._id, shop.shopName)}>
                                            <ion-icon name="trash-outline"></ion-icon>
                                        </Button>
                                    </ButtonContainer>
                                </ShopContent>
                            </ShopCard>
                        ))
                    ) : (
                        <NoShopsContainer>
                            <NoShopsText>Aucun shop trouvé.</NoShopsText>
                            <CreateShopButton onClick={() => window.location.href = '/'} target="_blank">Créer un nouveau shop</CreateShopButton>
                        </NoShopsContainer>
                    )}
                </CardContainer>
            )}
        </Container>
    );
}

export default MesShops;
